/* eslint-disable */

import React from 'react'
//componentsx
//images
import "../components/layout.css"
import '../components/Header/Header.css'
import './index.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import animationData from '../images/11264-swipe-up-arrows.json'
import ApplyUrlDiscount from '../components/ApplyUrlDiscount/ApplyUrlDiscount'
import BenefitsV2 from '../components/BenefitsV2/BenefitsV2';
import BenefitsV2Mobile from '../components/BenefitsV2/BenefitsV2Mobile';
import CartContext from '../components/Context/CartContext';
import ComparisonGrid from '../components/ComparisonGrid/ComparisonGrid'
import DesktopMenu from '../components/Header/DesktopMenu';
import Flavors from '../components/Flavors/Flavors'
import Footer from '../components/Footer'
import Formulas from '../components/Formulas/Formulas'
import Guarantee from '../components/Guarantee/Guarantee'
import Lottie from 'react-lottie'
import MediaQuery from 'react-responsive'
import MobileLogoCart from '../components/Header/MobileLogoCart';
import MobileMenu from '../components/Header/MobileMenu';
import Seo from '../components/SEO'
import Testimonials from '../components/Testimonials/Testimonials';
import ValueProp1 from '../components/ValueProp1/ValueProp1';
import { motion} from "framer-motion";
import { navigate } from "gatsby"
import { track } from '../../tracking'
import {StaticImage} from 'gatsby-plugin-image'
import {useContext} from 'react'
import {useEffect, useRef} from 'react'

const headerImg =  '../images/websiteimage.png'






//gradients
// pink - 'linear-gradient(45deg, #fad0c4 0%, #ffd1ff 100%)' 
// blue - 'linear-gradient(45deg, #fff1eb 0%, #ace0f9 100%)'
// green - 'linear-gradient(60deg, #abecd6 0%, #fbed96 100%)'

const StoreIndex = ({location}) => {
  //const { Mixpanel } = useStore()
  const {cartCount} = useContext(CartContext)
  const ref = useRef(null)

  useEffect(() =>{
    //could track different home page variants here
    track('Landing Page View')
    //console.log('u3',Mixpanel)
    //Mixpanel?.track('Landing Page View-test-v2')
    
  },[])
  
  useEffect(() => {
    console.log('redirecting to https://www.click.bender.buzz')
    window.location.replace('https://www.click.bender.buzz');
  }, []);



  async function handleCTA(location,ctaText){
    track('Landing Page CTA Click', {'Location':location, 'CTA Text':ctaText})
    navigate('/electrolyte-powder-drink-that-prevents-hangovers')
  }

  function handleScrollDownClick(){
    ref.current?.scrollIntoView({behavior: 'smooth'});
  }

  const navDropIn = {
    offscreen: {
      opacity: 1,
      y:-20,
    },
    onscreen:{
      opacity: 1,
      y:0,
      transition: {
        duration: .75,
        ease: 'easeInOut',
      }
    }
  }

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
   
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };


  return (
    <>
      
      <Seo
        title='Bender - Electrolyte powder formulated to prevent hangovers'
        description={`Formulated with electrolytes and other ingredients to help your body process alcohol, Bender is the perfect companion for your next night out.`}

      />
      <div className = 'home-page-container'>

        <motion.div 
          className = 'section-scroll' 
          style = {{height:'100vh'}}
          animate = {{
            backgroundImage:['linear-gradient(45deg, #fad0c4 0%, #ffd1ff 100%)','linear-gradient(45deg, #fff1eb 0%, #ace0f9 100%)','linear-gradient(60deg, #abecd6 0%, #fbed96 100%)'],
          }}
          transition={{
            duration: 5,
            ease: "easeInOut",
            yoyo: Infinity,
        
          }}
        >
          <div className= 'home-page-header-wrap'>
            <div className = 'header-container'>
              <MediaQuery maxWidth= {600}>
                <MobileMenu
                  location={location}
                  cartCount={cartCount}
                />
                <MobileLogoCart />
              </MediaQuery>
              <MediaQuery minWidth= {600}>
            
                <motion.div
                  initial="offscreen"
                  whileInView="onscreen"
                  viewport={{ once: true, amount: 0.8 }}
                  variants = {navDropIn} 
                >
                  <DesktopMenu
                    location={location}
                    cartCount={cartCount}

                  />
                </motion.div>

              </MediaQuery>
            </div>
       

            <div className="home-page-header">
              <ValueProp1 handleCTA={handleCTA}/>
              
            </div>
            
            <div className = 'lottie-header-div' onClick  ={handleScrollDownClick} role = 'button' >
                <Lottie options={defaultOptions}  style= {{transform:'rotate(180deg)'}} height={75} width={75} />
            </div>
           
            
          </div>
        </motion.div>

        

        <div 
           className="section-scroll" 
         
        >
          <Testimonials handleCTA={handleCTA}/>
        </div>
        <div 
          className="section-scroll" 
        >
          <MediaQuery maxWidth= {800}>
            <BenefitsV2Mobile />
          </MediaQuery>

          <MediaQuery minWidth= {800}>
            <BenefitsV2 />
          </MediaQuery>

         
        </div>

        <div  
          className="section-scroll" 
        >
          <Formulas handleCTA = {handleCTA}/>
        </div>

       
        {/*<motion.div 
          initial="offscreen"
          whileInView="onscreen"
          viewport={{ once: true, amount: 0.8 }} variants = {backgroundVariantsV2} className="section-scroll" 
        >
          <PriceRow handleCTA = {handleCTA} />
        </motion.div>*/}

        <div className="section-scroll"  >
          <Flavors handleCTA = {handleCTA} />
        </div>

        <div className="section-scroll" >
          <ComparisonGrid />
        </div>

        


   
        <div className="section-scroll" 
        >
          <Guarantee handleCTA = {handleCTA}/>
        </div>
        
        
        
        <div className="section-scroll" style = {{height:'auto', backgroundImage:'linear-gradient(60deg, #abecd6 0%, #fbed96 100%)'}}>
          <Footer />
        </div>
      </div>
      <ApplyUrlDiscount location={location} />
    </>
  )
}

export default StoreIndex


{/*
 
            <div className = 'header-container'>
                <TikTokOffer handleCTA={handleCTA} lottieOptions={defaultOptions} handleScrollDownClick = {handleScrollDownClick}/>
                {/* <PriceRow handleCTA = {handleCTA} buttonVariant = 'tiktok offer' />
              </div>


*/}